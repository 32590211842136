import Vue from 'vue'
import VueRouter from 'vue-router'

// 为了首屏加载快，所以首页不使用懒加载
import Main from '@/views/main/index'
import Layout from '@/views/layout/index'
const originalPush = VueRouter.prototype.push

// 处理路由跳转会报错的问题
VueRouter.prototype.push = function push(...rest) {
  return originalPush.apply(this, rest).catch((err) => err)
}

Vue.use(VueRouter)
// 自动扫描 modules 里面的路由模块，路由模块请根据业务自行拆分
// const files = require.context('./modules', false, /\.js$/)

const routes = []
// // 获取所有的路由内容
// files.keys().forEach((key) => {
//   const file = files(key).default
//   // 根据导出的内容判断是否数组，如果数组需使用扩展运算符
//   if (Array.isArray(file)) {
//     routes.push(...file)
//   } else {
//     routes.push(file)
//   }
// })

const constantRoutes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '',
        component: Main,
        name:'Main',
        meta:{
          keepAlive:true
        }
      },
      {
        path: 'page2',
        component: () => import('@/views/main/index2'),
        meta:{
          keepAlive:false
        }
      },
      {
        path: 'news',
        component: () => import('@/views/news/index'),
        meta:{
          keepAlive:false
        }
      },
      {
        path: 'newList',
        component: () => import('@/views/news/newList'),
        meta:{
          keepAlive:false
        }
      },
      {
        path: 'book',
        component: () => import('@/views/book/index'),
        meta:{
          keepAlive:false
        }
      },
      {
        path: 'card',
        component: () => import('@/views/book/card'),
        meta:{
          keepAlive:false
        }
      },
      {
        path: 'cancel',
        component: () => import('@/views/book/cancel'),
        meta:{
          keepAlive:false
        }
      },
      {
        path: 'zero',
        component: () => import('@/views/book/zero'),
        meta:{
          keepAlive:false
        }
      },
      {
        path: 'search',
        component: () => import('@/views/search/index'),
        meta:{
          keepAlive:true
        }
      },
      {
        path: 'articleList',
        component: () => import('@/views/news/articleList'),
        meta:{
          keepAlive:false
        }
      },
      {
        path: 'download',
        component: () => import('@/views/download'),
        meta:{
          keepAlive:false
        }
      }
    ]
  },
  // 404 页面路由
  // {
  //   path: '*',
  //   name: 'NotFound',
  //   component: () => import('@/views/error-page/404'),
  //   meta: {
  //     title: '页面走丢了'
  //   }
  // }
]

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
    routes: [...constantRoutes, ...routes]
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to, from, next) => {
  // document.title = to.meta.title
  next()
})

export default router
