<template>
  <div class="footer">
    <div class="device_pc">
      <div class="footer-container">
        <div class="logo-box">
          <img :src="baseUrl + system_config.image" class="logo" alt="">
          <div class="title">{{system_config.title}}</div>
        </div>
        <ul class="footer-navbox center-between">
          <li v-for="item in other" :key="'pc'+item.id">
            <ul>
              <li class="fs_22 head">{{item.name}}</li>
              <li class="fs_22" v-for="sonItem in item.child" @click="goPage(sonItem)" :key="'son'+sonItem.id">{{sonItem.title}}</li>
              <!-- <li class="fs_22">采购门户</li>
              <li class="fs_22">商家中心</li>
              <li class="fs_22">安全应急响应中心</li>
              <li class="fs_22">认证管理信息系统</li> -->
            </ul>
          </li>
          <!-- <li>
            <ul>
              <li class="fs_22 head">服务与支持</li>
              <li class="fs_22">常用服务</li>
            </ul>
          </li>
          <li>
            <ul>
              <li class="fs_22 head">帮助中心</li>
              <li class="fs_22">联系我们</li>
              <li class="fs_22">银联在线支付帮助</li>
            </ul>
          </li> -->
        </ul>
        <div class="footer-qrcode center-between">
          <div class="footer-qrcode__item">
            <img src="" class="qrcode" alt="">
            <div class="desc">官方微信</div>
          </div>
          <div class="footer-qrcode__item">
            <img src="" class="qrcode" alt="">
            <div class="desc">官方微博</div>
          </div>
        </div>
      </div>
      <div class="footer-bottom centery-between">
        <a href="javascript:;">网站使用条款</a>
        <div class="footer-copyright centery-between">
          <a href="javascript:;">© 中国银联版权所有</a>
          <a href="javascript:;">沪ICP备07032180号-2</a>
          <a href="javascript:;"><img src="@/assets/image/beian.png" alt=""> 沪公网安备31011502002335号</a>

        </div>
      </div>
    </div>
    <div class="device_mobile" :style="{ height: openMenu ? '0px' : 'auto', visibility: openMenu ? 'hidden': 'visible' }">

      <Collapse :border="false" v-model="activeName" accordion>
        <CollapseItem v-for="item, index in other" :key="item.id" :title="item.name" :name="index + 1" :border="false">
          <a :href="it.href" class="clause" style="background: #fff;" v-for="it in item.child" @click="goPage(it)" :key="it.id">{{ it.title }}</a>
        </CollapseItem>
        <!-- <CollapseItem title="其他链接" name="1" :border="false">内容</CollapseItem>
        <CollapseItem title="服务与支持" name="2" :border="false">内容</CollapseItem>
        <CollapseItem title="帮助中心" name="3" :border="false">内容</CollapseItem> -->
      </Collapse>
      <!-- <div class="clause">站内使用条款</div> -->
      <div class="connect">
        <div class="home_logo_c">
          <img :src="baseUrl + system_config.image" class="home_logo" alt="">
          <div class="home_title">{{system_config.title}}</div>
        </div>
        <div class="footer_icons">
          <img src="@/assets/image/wechat.png" alt="">
          <img src="@/assets/image/sina.png" alt="">
        </div>
      </div>

      <div class="footer-bottom">
        <div class="footer-copyright">
          <a href="javascript:;">© 中国银联版权所有</a>
          <a href="javascript:;">沪ICP备07032180号-2</a>
          <a href="javascript:;"><img src="@/assets/image/beian.png" alt=""> 沪公网安备31011502002335号</a>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex"
  import { Collapse, CollapseItem } from 'vant';
  import 'vant/lib/index.css';
  import {baseUrl} from '@/setting'
  export default {
    components: {
      Collapse,
      CollapseItem,
    },

    data() {
      return {
        baseUrl,
        activeName: '',
      }
    },

    computed: {
      ...mapState("nav", ["openMenu", "other"]),
      ...mapState("config", ["system_config"]),
    }, // computed
    methods:{
      goPage(it){
        if(it.href){
          window.location.href = it.href
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  ul {
    padding: 0;
  }
  li {
    list-style: none;
  }
  * {
    box-sizing: border-box;
  }
  .footer {
    width: 1920rem;
    height: calc(320rem + 52rem)320rem + 52rem;

    .device_pc {
      .footer-container {
        display: flex;
        width: 1920rem;
        height: 320rem;
        background-color: #f2f2f2;
  
        .logo-box {
          cursor: pointer;
          display: flex;
          margin: 51rem 212rem 0 207rem;
          .logo{
            height: 55rem;
            padding-right: 15rem;
          }
          .title {
            line-height: 55rem;
            font-weight: bold;
            font-size: 24rem;
            color: #CA3E3C;
          }
        }
      }
  
      .footer-navbox {
        width: 608rem;
        margin: 50rem 0 0;
        li {
          cursor: pointer;
          height: 42rem;
          line-height: 42rem;
          color: #737373;
          font-weight: 500;
  
          &.head {
            color: #000000;
            font-weight: 600;
          }
        }
      }
      .footer-qrcode {
        width: 259rem;
        margin: 0 0 0 229rem;
  
        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 103rem;
          margin: 52rem 0 0;
  
          .qrcode {
            width: 103rem;
            height: 103rem;
            margin: 0 0 26rem;
            background-color: #ffffff;
          }
          .desc {
            height: 25rem;
            line-height: 25rem;
            color: #000000;
            font-size: 18rem;
            font-weight: 500;
            font-family: PingFang SC, PingFang SC;
          }
        }
      }
  
      .footer-bottom {
        width: 1920rem;
        height: 52rem;
        padding: 0 190rem 0 197rem;
        background-color: #CA3E3C;
        * {
          color: #FFFFFF;
          font-size: 18rem;
          font-weight: 500;
          font-family: PingFang SC, PingFang SC;
        }
      }
      .footer-copyright {
        width: 737rem;
  
        img {
          width: 23rem;
          height: 23rem;
          vertical-align: middle;
        }
      }
    }

    .device_mobile {

      ::v-deep .van-cell {
        background: #f2f2f2;
        border-bottom: 1px solid #fff;
      }
      ::v-deep .van-collapse-item__content {
        padding: 0;
      }

      .clause {
        display: flex;
        align-items: center;
        width: 100vw;
        height: 48px;
        padding: 0 16px;
        background: #f2f2f2;
        border-bottom: 1px solid #fff;
        color: #323233;
        font-size: 14px;
      }

      .connect {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        height: 104px;
        padding: 0 16px;
        background: #f2f2f2;

        .home_logo_c {
          display: flex;
          cursor: pointer;
          .home_logo {
            height: 28px;
            padding-right: 8px;
          }
          .home_title{
            // font-weight: bold;
            font-size: 12px;
            line-height: 28px;
            color: #CA3E3C;
          }
        }
      }
      .footer_icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 110px;
        height: 40px;
        >img {
          width: 40px;
          height: 40px;
        }
      }

      .footer-bottom {
        // width: 1920rem;
        height: 28px;
        // padding: 0 190rem 0 197rem;
        background-color: #CA3E3C;
        * {
          color: #FFFFFF;
          font-size: 9px;
          font-weight: 500;
          font-family: PingFang SC, PingFang SC;
        }
      }
      .footer-copyright {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100vw;
        height: 28px;

        img {
          width: 10px;
          height: 10px;
          vertical-align: middle;
        }
      }
    }

  }

</style>